import { Component } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrl: './legal.component.css',
  standalone: true,
})
export class TermsComponent {
  content = {
    companyName: "DIGI America",
    companyFullName: "DIGI America, Inc",
    country: "The United States of America"
  }
}
