import { Component } from '@angular/core';
import { ToastService } from './toast.service';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-toast-container',
    template: `
    <div class="toast-container position-fixed bottom-0 start-50 translate-middle-x pb-5">
      <ngb-toast
        *ngFor="let toast of toastService.toasts"
        [class]="['bg-' + toast.variant, 'border-primary', toast.text]"
        [autohide]="true"
        [delay]="toast.delay"
        (hidden)="toastService.remove(toast)"
      >
        <div class="d-flex align-items-center">
          <fa-icon *ngIf="toast.icon" [icon]="toast.icon" class="me-2"></fa-icon>
          <div>
            <strong *ngIf="toast.title" class="me-auto">{{ toast.title }}</strong>
            <div>{{ toast.message }}</div>
          </div>
        </div>
      </ngb-toast>
    </div>
  `,
    styles: [`
    .toast-container {
      z-index: 1200;
    }
    
    :host ::ng-deep .toast {
      background-color: var(--bs-white);
      border-left-width: 4px !important;
    }
    
    :host ::ng-deep .toast.border-success {
      border-left-color: var(--bs-success) !important;
    }
    
    :host ::ng-deep .toast.border-danger {
      border-left-color: var(--bs-danger) !important;
    }
    
    :host ::ng-deep .toast.border-warning {
      border-left-color: var(--bs-warning) !important;
    }
    
    :host ::ng-deep .toast.border-info {
      border-left-color: var(--bs-info) !important;
    }
  `],
    standalone: true,
    imports: [CommonModule, NgbToast, FaIconComponent]
})
export class ToastContainerComponent {
    constructor(public toastService: ToastService) { }
}