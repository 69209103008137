import { Component, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.dismiss(false)">
        <fa-icon [icon]="faTimes" class="me-2"></fa-icon>{{ cancelText }}
      </button>
      <button
        type="button"
        [class]="'btn btn-' + confirmButtonType"
        (click)="modal.close(true)">
        <fa-icon [icon]="confirmIconClass" class="me-2"></fa-icon>{{ confirmText }}
      </button>
    </div>
  `,
    standalone: true,
    imports: [FaIconComponent]
})
export class ConfirmationDialogComponent {
    @Input() title = 'Please Confirm';
    @Input() message = 'Are you sure you want to perform this action?';
    @Input() confirmText = 'Confirm';
    @Input() cancelText = 'Cancel';
    @Input() confirmButtonType: 'primary' | 'danger' | 'warning' = 'primary';
    @Input() confirmIconClass = faCheck;

    faTimes = faTimes;

    constructor(public modal: NgbActiveModal) { }
}