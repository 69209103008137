<div class="row mb-3">
    <div class="col">
        <h1 class="mb-0">Controllers</h1>
    </div>
    <div class="col-auto">
        <button class="btn btn-primary" [routerLink]="['/company', companyId, 'controller-new']">Add Controller</button>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<table class="table table-stripe" *ngIf="controllers$ | async as controllers; else loading">
    <thead>
        <tr>
            <th>Name</th>
            <th>Mac Address</th>
            <th>Device Count</th>
            <th>Initalized Date</th>
            <th>Last Updated</th>
        </tr>
    </thead>
    <tbody class="table-group-divider">
        <tr *ngFor="let controller of controllers">
            <td>
                <a [routerLink]="['/company', companyId, 'controllers', controller.id]">
                    {{ controller.name }}
                </a>
            </td>
            <td>{{ controller.macAddress | macAddress }}</td>
            <td>{{ controller.deviceCount }}</td>
            <td>{{ controller.firstSeen | utcToLocal:'medium' }}</td>
            <td>{{ controller.lastSeen | utcToLocal:'medium' }}</td>
        </tr>
    </tbody>
</table>