import { Injectable } from '@angular/core';
import { ToastInfo } from './toast.model';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    toasts: ToastInfo[] = [];

    private defaultDelay = 5000;

    show(toast: Partial<ToastInfo>) {
        this.toasts.push({
            message: toast.message!,
            title: toast.title,
            variant: toast.variant || 'info',
            delay: toast.delay || this.defaultDelay,
            icon: this.getIconForVariant(toast.variant),
            text: this.getTextForVarient(toast.variant)
        });
    }

    remove(toast: ToastInfo) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    // Convenience methods
    success(message: string, title?: string, delay?: number) {
        this.show({
            message,
            title,
            variant: 'success',
            delay,
            icon: faCheckCircle
        });
    }

    error(message: string, title?: string, delay?: number) {
        this.show({
            message,
            title,
            variant: 'danger',
            delay,
            icon: faExclamationCircle
        });
    }

    warning(message: string, title?: string, delay?: number) {
        this.show({
            message,
            title,
            variant: 'warning',
            delay,
            icon: faExclamationTriangle
        });
    }

    info(message: string, title?: string, delay?: number) {
        this.show({
            message,
            title,
            variant: 'info',
            delay,
            icon: faInfoCircle
        });
    }

    private getTextForVarient(variant: string): string {
        switch (variant) {
            case 'success':
                return 'text-light';
            case 'danger':
                return 'text-light';
            case 'warning':
                return 'text-dark';
            case 'info':
                return 'text-dark';
            default:
                return 'text-light';
        }
    }

    private getIconForVariant(variant?: string): IconDefinition {
        switch (variant) {
            case 'success':
                return faCheckCircle;
            case 'danger':
                return faExclamationCircle;
            case 'warning':
                return faExclamationTriangle;
            case 'info':
                return faInfoCircle;
            default:
                return null;
        }
    }
}