import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { DramsService } from 'src/app/services/drams.service';
import { ShelfEdgeModel } from 'src/app/types/ShelfEdgeModel';
import { VideoInfo } from 'src/app/types/VideoInfo';
import { UtcToLocalTimePipe } from "../../pipes/utcToLocal.pipe";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoadingComponent } from "../../components/loading/loading.component";

@Component({
    selector: 'app-drams-video-list',
    standalone: true,
    imports: [CommonModule, FormsModule, RouterModule, UtcToLocalTimePipe, FontAwesomeModule, LoadingComponent],
    templateUrl: "./video-list.component.html",
})
export class VideoListComponent implements OnInit {
    companyId = '';

    faTrash = faTrash;
    vidoes$!: Observable<VideoInfo[]>;
    modelOptions$: Observable<ShelfEdgeModel[]>;

    constructor(
        private route: ActivatedRoute,
        private dramsService: DramsService
    ) {
        this.modelOptions$ = this.dramsService.getModels();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.companyId = params.get('companyId') || '';
            this.updateVideos();
        });
    }

    deleteVideo(videoId: string) {
        this.dramsService.deleteVideo(videoId, this.companyId).subscribe(
            () => {
                this.updateVideos();
            }
        );
    }

    private updateVideos() {
        this.vidoes$ = this.dramsService.getVideos(this.companyId);
    }
}