<h1>Terms and Conditions</h1>

<section>
    <p>Before using this website, please read the following terms of use ("Terms") and only use the website if you agree
        to abide by these terms. Regardless of whether you agree with the Terms, by using this website you confirm that
        you accept the terms and that you agree to abide by them.</p>
    <ol>
        <li>
            <p>Website operation</p>
            <p>This website is operated by {{ content.companyFullName }} ("we" "us", "our" or "{{
                content.companyName }}" as required by
                context).</p>
        </li>
        <li>
            <p>Scope of application of terms</p>
            <p>These Terms apply to use of Internet services ("The Services") provided through this website.</p>
        </li>
        <li>
            <p>Changes to the Terms</p>
            <p>We reserve the right to change or remove any of the Terms at any time. Therefore, please review the terms
                each time you use the website to ensure your acceptance of the latest version. Changes to these Terms
                become effective at the time they are posted to this website. {{ content.companyName }} shall not be
                liable for
                losses of any sort incurred by users of the website as a consequence of changes to the Terms.</p>
        </li>
        <li>
            <p>Use of this website</p>
            <ul>
                <li>By using this website, you agree that you will be responsible for all consequences arising out of
                    its use.</li>
                <li>We reserve the right to withdraw or amend The Services we provide through our website without
                    notice. {{ content.companyName }} shall not be liable for losses of any sort incurred by users of
                    the website as
                    a consequence of withdrawal or suspension of The Services.</li>
                <li>Further, {{ content.companyName }} shall not be liable for damage to hardware or software, loss of
                    data or other
                    damages incurred by users or third parties as a consequence of use of this website.</li>
            </ul>
        </li>
        <li>
            <p>Disclaimer regarding information</p>
            <p>{{ content.companyName }} has taken care to ensure that the information provided on this website is
                trustworthy and
                can be regarded by users as reliable. However, we make no warranty, express or implied, as to the
                accuracy of text or other information provided through this website, its fitness for any particular
                purpose, whether the information is up-to-date, or as to the safety of the website itself (e.g., freedom
                for interruption of The Services, occurrence of errors, or infection by computer viruses or other
                malicious software), and we shall not accept liability for any errors in this website's content.
                Further, we shall not be liable of any damages incurred as a consequence of using information provided
                through this website, or as a consequence of inability to use such information.</p>
        </li>
        <li>
            <p>Trademarks</p>
            <p>The rights to all trademarks, trade names, and emblems that are used in this website and which belong to
                {{ content.companyName }} or its affiliated companies are protected by laws and treaties of and between
                various
                countries governing trademarks, unfair competition, and trade, and may not be used without the express
                permission of {{ content.companyName }} or its affiliated companies. All other company and product names
                mentioned
                in this website are also the trade names, trademarks or registered trademarks of their respective
                owners. None of these trademarks, trade names or symbols may be used without the permission of their
                owners.</p>
        </li>
        <li>
            <p>Copyright</p>
            <ul>
                <li>Unless otherwise indicated, the copyright to all content on this website is the property of {{
                    content.companyName }}. This copyright is protected by the copyright law of {{ content.country }}
                    and by laws of or
                    treaties with
                    other countries, and except as provided by such laws or treaties, may not be used (including
                    reproduction, modification, uploading, posting, transmission, distribution, licensing, sale, or
                    publication) without the permission of {{ content.companyName }}.</li>
                <li>The software distributed through this site is copyrighted work that is the property of {{
                    content.companyName }}
                    or other entities. This software may only be used in accordance with the terms of use set forth by
                    {{ content.companyName }} or other copyright owners in accordance with the copyright law of {{
                    content.country }} or
                    the laws of
                    or treaties with other countries.</li>
            </ul>
        </li>
        <li>
            <p>Prohibitions</p>
            <p>Users of this site are prohibited from undertaking any of the following activities.</p>
            <ul>
                <li>Activities that are or might be contrary to public order and morals.</li>
                <li>Activities that would or could lead to criminal actions</li>
                <li>Activities that infringe upon or might lead to infringement of property rights of {{
                    content.companyName }}, its
                    affiliated companies, or third parties</li>
                <li>Activities that might adversely affect the good name or reputation of {{ content.companyName }}, its
                    affiliated
                    companies, or third parties</li>
                <li>Activities that would or could cause loss or disadvantage to {{ content.companyName }}, its
                    affiliated
                    companies, or third parties</li>
                <li>Activities that involve submission of false information, such as registration of other people's
                    email addresses</li>
                <li>Activities that are related to business or business profits</li>
                <li>Activities involving the use or provision of any computer virus or other harmful or potentially
                    harmful software</li>
                <li>Any activity that is or might be prohibited by law</li>
                <li>Any activity that violates these Terms, or which causes or might cause damage to {{
                    content.companyName }}, its
                    affiliated companies, or third parties</li>
                <li>Any other activity determined to be improper by {{ content.companyName }}</li>
            </ul>
        </li>
        <li>
            <p>Links</p>
            <ul>
                <li>
                    <p>Links to this website are not permitted from any of the following:</p>
                    <ul>
                        <li>Any website that provides information that may be offensive to public order and morals</li>
                        <li>Any website that slanders {{ content.companyName }} or its affiliated companies or their
                            directors or
                            employees, or which promotes or slanders the activities of any company or individual</li>
                        <li>Any site that slanders or misrepresents the products and/or services or {{
                            content.companyName }} or its
                            affiliated companies</li>
                        <li>Links to the website from within a frameset or other links that obscure the fact that the
                            content is part of this website</li>
                    </ul>
                </li>
                <li>Users agree that they will delete any links that violate the above provisions, whether or not such
                    deletion is requested by {{ content.companyName }}.</li>
                <li>Except for web sites of affiliates of {{ content.companyName }}, responsibility for management of
                    third-party
                    web sites linking to this site lies with the third parties operating such web sites. The existence
                    of such links does not indicate any relationship between {{ content.companyName }} and the companies
                    or entities
                    operating such web sites, nor does it indicate any recommendation on the part of {{
                    content.companyName }} of
                    the content of such sites. Further, {{ content.companyName }} shall not be responsible in any way
                    for the
                    content of third party linked web sites, or for any consequences arising out of use of such sites.
                </li>
                <li>When using the web sites of {{ content.companyName }} affiliated companies linked to from this
                    website, please
                    follow the terms and conditions for use of those web sites.</li>
            </ul>
        </li>
        <li>
            <p>Inappropriate use</p>
            <p>Any use of this website that is deemed by us to be violation of the Terms of use shall be deemed
                inappropriate, and use of this website by the user in question shall be terminated.</p>
        </li>
        <li>
            <p>Information provided by users</p>
            <p>Excluding cases in which this website explicitly solicits submission of users' proprietary information or
                other information regarding technology, sales, or products, {{ content.companyName }} does not accept
                submission of
                information from users. Further, except in cases in which a separate confidentiality agreement has been
                concluded, {{ content.companyName }} will not be responsible for maintaining the confidentiality of any
                information
                submitted by users by any means.</p>
        </li>
        <li>
            <p>Export restrictions</p>
            <p>Users are responsible for ensuring compliance with any legislative and/or regulatory restrictions in
                existence in their own country with regard to export of technology or software obtained through this
                website (for example, export to a foreign country or provision to a non-resident).</p>
        </li>
        <li>
            <p>Termination of operation</p>
            <p>{{ content.companyName }} reserves the right to terminate operation of this website at any time without
                prior
                notification to users. {{ content.companyName }} shall not be liable for losses of any sort incurred by
                users of the
                website as a consequence of site closure or termination.</p>
        </li>
        <!--li>
            <p>Applicable law and court of jurisdiction</p>
            <p>This website and these Terms are governed by the laws of {{ content.country }}. In the event that any
                dispute arises with
                regard to these Terms or use of this website, resolution of such dispute will be by trial under the
                exclusive jurisdiction of the Tokyo District Cout.</p>
        </li-->
    </ol>
</section>