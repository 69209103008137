<ng-container *ngIf="vidoes$ | async as videos; else loading">

    <div class="row mb-3">
        <div class="col">
            <h1 class="mb-0">Videos</h1>
        </div>
        <div class="col-auto">
            <button class="btn btn-primary" [routerLink]="['/company', companyId, 'upload-video']">
                Add Video
            </button>
        </div>
    </div>

    <div *ngIf="videos.length === 0" class="alert alert-info">
        <h2 class="text-center">No Videos!</h2>
    </div>

    <table *ngIf="videos.length > 0" class="table table-stripe">
        <thead>
            <tr>
                <th>Name</th>
                <th>Created</th>
                <th>Resolution</th>
                <th></th>
            </tr>
        </thead>
        <tbody class="table-group-divider">
            <tr *ngFor="let video of videos">
                <td><a [routerLink]="[video.id]">{{ video.name }}</a></td>
                <td>{{ video.created | utcToLocal }}</td>
                <td>{{ video.width }}x{{video.height}}</td>
                <td><button class="btn btn-danger" (click)="deleteVideo(video.id)"><fa-icon
                            [icon]="faTrash"></fa-icon></button></td>
            </tr>
        </tbody>
    </table>
</ng-container>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>