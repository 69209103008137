import {
  Routes,
} from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { authGuardFn } from '@auth0/auth0-angular';
import { ControllersListComponent } from './pages/controller/controller-list.component';
import { ControllerDetailsComponent } from './pages/controller/controller-details.component';
import { VideoUploadComponent } from './pages/video/video-upload.component';
import { VideoListComponent } from './pages/video/video-list.component';
import { ControllersNewComponent } from './pages/controller/controller-new.component';
import { VideoDetailsComponent } from './pages/video/video-details.component';
import { ErrorComponent } from './pages/error/error.component';
import { ControllerDeviceNewComponent } from './pages/controller/controller-device-new.component';
import { TermsComponent } from './pages/legal/terms.component';
import { PrivacyComponent } from './pages/legal/privacy.component';

export const routes: Routes = [
  { path: 'company/:companyId/controller-new', component: ControllersNewComponent },
  { path: 'company/:companyId/controllers', component: ControllersListComponent },
  { path: 'company/:companyId/controllers/:controllerId', component: ControllerDetailsComponent },
  { path: 'company/:companyId/controllers/:controllerId/device-new', component: ControllerDeviceNewComponent },
  { path: 'company/:companyId/videos', component: VideoListComponent },
  { path: 'company/:companyId/videos/:videoId', component: VideoDetailsComponent },
  { path: 'company/:companyId/upload-video', component: VideoUploadComponent },

  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [authGuardFn],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'overview',
    component: HomeComponent,
  },
  {
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full'
  },
];
