import { Injectable } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Observable } from "rxjs";
import { DAIToken } from "../types/DAIToken";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public accessToken: DAIToken;
    public permissions: string[];
    public companyNumber: string;

    constructor(private auth: AuthService) {
        this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                this.getAccessToken();
                this.getPermissions();
            }
        });
    }

    getAccessToken() {
        // Get the Access Token
        this.auth.getAccessTokenSilently().subscribe((token) => {
            this.accessToken = this.parseJwt(token);
        });
    }

    getPermissions() {
        // Get the permissions from the Access Token
        if (this.accessToken) {
            this.permissions = this.accessToken.permissions || [];
        }
    }

    getCustomerNumber(): string | null {
        if (this.accessToken && this.accessToken.company_number) {
            return this.accessToken.company_number;
        }
        return null;
    }

    parseJwt(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
}