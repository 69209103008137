import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DramsService } from '../../services/drams.service';
import { ESLController } from 'src/app/types/ESLController';
import { MacAddressFormatterPipe } from "../../pipes/macaddress.pipe";
import { UtcToLocalTimePipe } from "../../pipes/utcToLocal.pipe";
import { LoadingComponent } from "../../components/loading/loading.component";
import { Observable, ReplaySubject } from 'rxjs';

@Component({
    selector: 'app-drams-controllers-list',
    standalone: true,
    imports: [CommonModule, RouterModule, MacAddressFormatterPipe, UtcToLocalTimePipe, LoadingComponent],
    templateUrl: "controller-list.component.html",
    styles: []
})
export class ControllersListComponent implements OnInit {
    controllers$: Observable<ESLController[]>;
    companyId = '';

    private controllerSubject = new ReplaySubject<ESLController[]>();

    constructor(
        private route: ActivatedRoute,
        private dramsService: DramsService
    ) {
        this.controllers$ = this.controllerSubject.asObservable();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.companyId = params.get('companyId') || '';
            this.loadControllers();
        });
    }

    loadControllers() {
        this.dramsService.getControllers(this.companyId).subscribe(
            data => this.controllerSubject.next(data),
            error => console.error('Error fetching controllers:', error)
        );
    }
}