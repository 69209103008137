import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-mac-address-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <input
      class="form-control"
      type="text"
      [value]="formattedMacAddress"
      (input)="onInputChange($event)"
      placeholder="AA:BB:CC:DD:EE:FF"
      maxlength="17"
    >
  `,
  styles: [`
    input {
      font-family: monospace;
      width: 150px;
      padding: 5px;
      font-size: 14px;
    }
  `]
})
export class MacAddressInputComponent implements OnInit {
  @Input() macAddress = '';
  @Output() macAddressChange = new EventEmitter<string>();

  formattedMacAddress = '';

  ngOnInit() {
    this.formatMacAddress();
  }

  formatMacAddress() {
    // Remove non-hexadecimal characters and convert to uppercase
    const cleanMac = this.macAddress.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();

    // Insert colons every two characters
    this.formattedMacAddress = cleanMac.replace(/(.{2})/g, '$1:').slice(0, 17);
  }

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.toUpperCase();

    // Remove any character that's not a hexadecimal digit or colon
    value = value.replace(/[^0-9A-F:]/g, '');

    // Ensure we don't have two colons in a row
    value = value.replace(/:+/g, '');

    const groups = splitIntoPairs(value);

    // Join the groups with colons
    this.formattedMacAddress = groups.join(':');

    // Update the input value
    input.value = this.formattedMacAddress;

    if (value.length === 12) {
      // Emit the raw MAC address (without colons)
      this.macAddressChange.emit(this.formattedMacAddress.replace(/:/g, ''));
    }
  }

}

function splitIntoPairs(str) {
  // Use a regular expression to match pairs of characters
  // The final (.)? captures any remaining single character
  const matches = str.match(/.{1,2}/g) || [];

  // If the last group is a single character, pad it with an empty string
  if (matches.length > 0 && matches[matches.length - 1].length === 1) {
    matches[matches.length - 1] += '';
  }

  return matches;
}