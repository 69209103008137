import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { DramsService } from "src/app/services/drams.service";
import { MacAddressInputComponent } from "../../components/widgets/mac-address-input.component";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";

@Component({
    selector: 'app-drams-controllers-new',
    standalone: true,
    imports: [CommonModule, FormsModule, RouterModule, MacAddressInputComponent, NgbAlert],
    templateUrl: "controller-new.component.html",
    styles: []
})
export class ControllersNewComponent implements OnInit {
    name: '';
    macAddress: '';
    companyId = '';
    error = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dramsService: DramsService
    ) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.companyId = params.get('companyId') || '';
        });
    }

    onSubmit() {
        this.dramsService.createController(this.companyId, this.name, this.macAddress).subscribe(
            response => {
                console.log("Create Controller Response: ", response);
                this.router.navigate(["company", this.companyId, "controllers", response.id]);
            },
            badResponse => this.error = badResponse.error
        );
    }

}