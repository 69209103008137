<h1>Video Upload</h1>

<ng-container *ngIf="modelOptions$ | async as modelOptions; else loading">
    <form (ngSubmit)="onSubmit()">
        <div class="mb-3">
            <label class="form-label" for="modelType">Model</label>
            <select class="form-control" [(ngModel)]="modelType" name="modelType">
                <option [ngValue]="null" disabled>Select a model</option>
                <option *ngFor="let model of modelOptions" [ngValue]="model.type">
                    {{model.description}} ({{model.screenWidth}}x{{model.screenHeight}})
                </option>
            </select>
        </div>
        <div class="mb-3">
            <label class="form-label" for="videoFile">File</label>
            <input class="form-control" type="file" (change)="onFileSelected($event)" accept="video/*" name="videoFile">
        </div>
        <div class="mb-3">
            <label class="form-label" for="videoName">Video Name</label>
            <input class="form-control" type="text" [(ngModel)]="videoName" maxlength="50" placeholder="Video Name"
                name="videoName" />
        </div>
        <ngb-alert type="danger" *ngIf="error" (closed)="error = ''">{{ error }}</ngb-alert>

        <div *ngIf="uploadPercent > 0" class="mb-3">
            <ngb-progressbar [animated]="true" [value]="uploadPercent" [showValue]="true" height="1.8rem"
                type="success"></ngb-progressbar>
        </div>

        <button class="btn btn-primary" type="submit"
            [disabled]="!selectedFile || modelType === null || videoName.length < 5">
            Upload
        </button>
    </form>


</ng-container>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>