import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { MacAddressInputComponent } from "src/app/components/widgets/mac-address-input.component";
import { DramsService } from "src/app/services/drams.service";
import { ShelfEdgeDevice } from "src/app/types/ShelfEdgeDevice";
import { ShelfEdgeModel } from "src/app/types/ShelfEdgeModel";
import { LoadingComponent } from "../../components/loading/loading.component";

@Component({
    selector: 'app-drams-controller-device-new',
    standalone: true,
    imports: [CommonModule, FormsModule, NgbAlertModule, MacAddressInputComponent, RouterModule, LoadingComponent],
    templateUrl: "controller-device-new.component.html",
})
export class ControllerDeviceNewComponent implements OnInit {
    device: ShelfEdgeDevice = {
        name: '',
        macAddress: "",
        type: 0,
        videoId: null
    }

    error = '';
    companyId: string;
    controllerId: string;
    modelOptions$: Observable<ShelfEdgeModel[]>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dramsService: DramsService) {
    }

    ngOnInit() {
        this.modelOptions$ = this.dramsService.getModels();

        this.route.paramMap.subscribe(params => {
            this.companyId = params.get('companyId') || '';
            this.controllerId = params.get('controllerId') || '';
        });
    }

    public onSubmit() {
        this.dramsService.createControllerDevice(this.controllerId, this.device).subscribe(
            () => {
                this.router.navigate(['/company', this.companyId, 'controllers', this.controllerId]);
            },
            badResponse => this.error = badResponse.error
        )
    }

}