import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DramsService } from 'src/app/services/drams.service';
import { ShelfEdgeModel } from 'src/app/types/ShelfEdgeModel';
import { NgbAlert, NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from "../../components/loading/loading.component";

// Limit file size to just below the max of 30MB.
const MAX_FILE_SIZE = 29.95 * 1024 * 1024;

@Component({
  selector: 'app-drams-video-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbAlert, LoadingComponent, NgbProgressbar],
  templateUrl: "video-upload.component.html",
})
export class VideoUploadComponent implements OnInit {
  selectedFile: File | null = null;
  modelType: number | null = null;
  companyId = '';

  modelOptions$: Observable<ShelfEdgeModel[]>;
  videoName = '';
  error = '';
  uploadPercent = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dramsService: DramsService
  ) {
    this.modelOptions$ = this.dramsService.getModels();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.companyId = params.get('companyId') || '';
    });
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.selectedFile = fileList[0];
      this.videoName = this.selectedFile.name.split('.')[0];
    }
  }

  onSubmit() {
    this.error = '';
    if (this.videoName.length && this.selectedFile && this.modelType !== null && this.companyId) {
      if (this.selectedFile.size > MAX_FILE_SIZE) {
        this.error = "File Size is too large, please limit size to 30MB.";
        return;
      }

      this.dramsService.uploadVideo(this.selectedFile, this.videoName, this.modelType, this.companyId).subscribe({
        next: (event) => {
          if (event.status == 'progress') {
            this.uploadPercent = event.percentage;
          }
          else if (event.status == 'complete') {
            this.uploadPercent = 0;
            this.router.navigate(["company", this.companyId, "videos", event.result.id]);
          }
        },
        error: (badResponse) => {
          this.uploadPercent = 0;
          if (badResponse.status == 413) {
            this.error = "File size is too large, please limit file sizes to 30MB."
          }
          else if (typeof badResponse.error === "string") {
            this.error = badResponse.error;
          }
          else {
            this.error = "An unexpected error has occured.";
          }
        }
      });
    } else {
      this.error = 'Please fill in all fields and select a file';
    }
  }
  updateDownloadProgess(percentage: number) {
    this.uploadPercent = percentage;
  }
}