<h1>Create New Controller</h1>
<form (ngSubmit)="onSubmit()">
    <div class="mb-3">
        <label class="form-label" for="controllerName">Name</label>
        <input class="form-control" type="text" [(ngModel)]="name" maxlength="50" placeholder="Controller Name"
            name="controllerName" />
    </div>
    <div class="mb-3">
        <label class="form-label" for="controllerMacAddress">Mac Address</label>
        <app-mac-address-input class="control-form" [(macAddress)]="macAddress" />
    </div>
    <ngb-alert type="danger" *ngIf="error">{{ error }}</ngb-alert>

    <button class="btn btn-primary" type="submit">Create</button>
</form>