import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div #videoContainer class="video-container">
      <video #videoElement [src]="videoUrl" controls autoplay loop>
        Your browser does not support the video tag.
      </video>
    </div>
  `,
  styles: [`
    .video-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0;
    }
    video {
      max-width: 100%;
      max-height: 100%;
    }
  `]
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {
  @Input() videoUrl = '';

  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('videoContainer') videoContainer!: ElementRef<HTMLDivElement>;

  ngOnInit() {
    if (!this.videoUrl) {
      console.warn('No video URL provided to VideoPlayerComponent');
    }
  }

  ngAfterViewInit() {
    this.videoElement.nativeElement.addEventListener('loadedmetadata', () => this.adjustVideoSize());
  }

  @HostListener('window:resize')
  onResize() {
    this.adjustVideoSize();
  }

  private adjustVideoSize() {
    const video = this.videoElement.nativeElement;
    const container = this.videoContainer.nativeElement;
    const aspectRatio = video.videoWidth / video.videoHeight;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    if (containerWidth / aspectRatio < containerHeight) {
      video.style.width = '100%';
      video.style.height = 'auto';
    } else {
      video.style.width = 'auto';
      video.style.height = '100%';
    }
  }
}