import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DramsService } from 'src/app/services/drams.service';
import { ShelfEdgeModel } from 'src/app/types/ShelfEdgeModel';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from "../../components/loading/loading.component";

@Component({
  selector: 'app-drams-video-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbAlert, LoadingComponent],
  templateUrl: "video-upload.component.html",
})
export class VideoUploadComponent implements OnInit {
  selectedFile: File | null = null;
  modelType: number | null = null;
  companyId = '';

  modelOptions$: Observable<ShelfEdgeModel[]>;
  videoName = '';
  error = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dramsService: DramsService
  ) {
    this.modelOptions$ = this.dramsService.getModels();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.companyId = params.get('companyId') || '';
    });
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.selectedFile = fileList[0];
      this.videoName = this.selectedFile.name.split('.')[0];
    }
  }

  onSubmit() {
    if (this.videoName.length && this.selectedFile && this.modelType !== null && this.companyId) {
      this.dramsService.uploadVideo(this.selectedFile, this.videoName, this.modelType, this.companyId).subscribe(
        response => {
          this.router.navigate(["company", this.companyId, "videos", response.id]);
        },
        badResponse => this.error = badResponse.error
      );
    } else {
      this.error = 'Please fill in all fields and select a file';
    }
  }
}