<footer class="bg-light text-dark p-3">
  <div class="container">
    <div class="row">
      <p class="col-md-4 text-md-start mt-md-3">
        DIGI America Inc. <br> 76 Veronica Avenue Somerset, NJ 08873
      </p>
      <p class="col-md-4 d-none d-md-block text-md-center">
        DIGI Remote Ads Management System
      </p>
      <p class="col-md-4 text-md-end mt-md-5">
        <a href="/support" class="link-secondary">Support</a> | <a href="/terms" class="link-secondary">Terms and
          Conditions</a> | <a href="/privacy" class="link-secondary">Privacy
          Policy</a>
      </p>
    </div>
  </div>

</footer>