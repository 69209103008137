import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ShelfEdgeModel } from '../types/ShelfEdgeModel';
import { VideoInfo } from '../types/VideoInfo';
import { ESLController } from '../types/ESLController';
import { ShelfEdgeDevice } from '../types/ShelfEdgeDevice';
import { DRAMS_URL } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DramsService {
    private apiUrl = DRAMS_URL;

    constructor(private http: HttpClient) { }

    getModels(): Observable<ShelfEdgeModel[]> {
        return this.http.get<ShelfEdgeModel[]>(`${this.apiUrl}/models`);
    }

    getControllers(): Observable<ESLController[]> {
        return this.http.get<ESLController[]>(`${this.apiUrl}/controllers`);
    }

    createController(customer: string, name: string, macAddress: string): Observable<ESLController> {
        return this.http.post<ESLController>(`${this.apiUrl}/controllers`, {
            customer,
            name,
            macAddress
        });
    }

    getControllerById(controllerId: string): Observable<ESLController> {
        return this.http.get<ESLController>(`${this.apiUrl}/controllers/${controllerId}`);
    }

    getControllerDevices(controllerId: string): Observable<ShelfEdgeDevice[]> {
        return this.http.get<ShelfEdgeDevice[]>(`${this.apiUrl}/controllers/${controllerId}/devices`, { params: { 'filterAssigned': false } });
    }

    createControllerDevice(controllerId: string, device: object): Observable<ShelfEdgeDevice> {
        return this.http.post<ShelfEdgeDevice>(`${this.apiUrl}/controllers/${controllerId}/devices`, device);
    }

    updateDevice(controllerId: string, customer: string, device: ShelfEdgeDevice): Observable<ShelfEdgeDevice> {
        return this.http.put<ShelfEdgeDevice>(`${this.apiUrl}/controllers/${controllerId}/devices/${device.macAddress}`, device, { params: { customer } });
    }

    deleteControllerDevice(controllerId: string, customer: string, macAddress: string): Observable<unknown> {
        return this.http.delete(`${this.apiUrl}/controllers/${controllerId}/devices/${macAddress}`, { params: { customer } });
    }

    getVideos(company: string): Observable<VideoInfo[]> {
        return this.http.get<VideoInfo[]>(`${this.apiUrl}/videos`, { params: { company } });
    }

    getVideoById(videoId: string): Observable<VideoInfo> {
        return this.http.get<VideoInfo>(`${this.apiUrl}/videos/${videoId}`);
    }

    uploadVideo(file: File, videoName: string, modelType: number, customer: string): Observable<{ status: 'progress' | 'complete' | 'other', percentage?: number, result?: VideoInfo }> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', videoName);
        formData.append('modelType', modelType.toString());
        formData.append('customer', customer);

        const request = new HttpRequest('POST', `${this.apiUrl}/videos`, formData, {
            reportProgress: true
        });

        return this.http.request(request).pipe(
            map((event: HttpEvent<VideoInfo>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
                        return { status: 'progress', percentage: progress };

                    case HttpEventType.Response:
                        return { status: 'complete', result: event.body };

                    default:
                        return { status: 'other' };
                }
            })
        );
    }

    deleteVideo(videoId: string, customer: string): Observable<unknown> {
        return this.http.delete(`${this.apiUrl}/videos/${videoId}`, {
            params: { customer }
        });
    }
}