import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { DramsService } from 'src/app/services/drams.service';
import { VideoInfo } from 'src/app/types/VideoInfo';
import { UtcToLocalTimePipe } from "../../pipes/utcToLocal.pipe";
import { MacAddressFormatterPipe } from "../../pipes/macaddress.pipe";
import { VideoPlayerComponent } from 'src/app/components/video-player/video-player.component';

@Component({
    selector: 'app-drams-video-list',
    standalone: true,
    imports: [CommonModule, FormsModule, RouterModule, UtcToLocalTimePipe, MacAddressFormatterPipe, VideoPlayerComponent],
    templateUrl: "./video-details.component.html",
})
export class VideoDetailsComponent implements OnInit {
    companyId = '';
    videoId = '';

    video$!: Observable<VideoInfo>;

    constructor(
        private route: ActivatedRoute,
        private dramsService: DramsService
    ) {
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.companyId = params.get('companyId') || '';
            this.videoId = params.get('videoId') || '';
            this.video$ = this.dramsService.getVideoById(this.videoId);
        });
    }
}