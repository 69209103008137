import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SessionService } from './session.service';

export const customerParamsInterceptorFn: HttpInterceptorFn = (request, next) => {
    const sessionService = inject(SessionService);
    const customerNumber = sessionService.getCustomerNumber();

    if (customerNumber != null && shouldAddParams(request.url)) {
        const modifiedRequest = request.clone({
            params: request.params.set('customer', customerNumber)
        });
        return next(modifiedRequest);
    }
    return next(request);
};

function shouldAddParams(url: string): boolean {
    return url.includes("api.digiamerica.us") ||
        url.includes("api-dev.digiamerica.us") ||
        url.includes('localhost:8080');
}