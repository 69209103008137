<h1>Add New Display</h1>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<form (ngSubmit)="onSubmit()" *ngIf="modelOptions$ | async as modelOptions; else loading">
    <div class="mb-3">
        <label class="form-label" for="deviceName">Name</label>
        <input class="form-control" type="text" [(ngModel)]="device.name" maxlength="50" placeholder="Device Name"
            name="deviceName" />
    </div>
    <div class="mb-3">
        <label class="form-label" for="modelType">Model</label>
        <select class="form-control" [(ngModel)]="device.type" name="modelType">
            <option [ngValue]="null" disabled>Select a model</option>
            <option *ngFor="let model of modelOptions" [ngValue]="model.type">
                {{model.description}} ({{model.screenWidth}}x{{model.screenHeight}})
            </option>
        </select>
    </div>
    <div class="mb-3">
        <label class="form-label" for="macAddress">Mac Address</label>
        <app-mac-address-input class="control-form" [(macAddress)]="device.macAddress" />
    </div>
    <ngb-alert type="danger" *ngIf="error">{{ error }}</ngb-alert>

    <button class="btn btn-primary" type="submit">Create</button>
</form>