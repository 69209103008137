<h1>Privacy Policy</h1>

<section>
    <p>DIGI Group (“we”) handle personal data based on the following policy (the “Policy”):</p>
    <ol>
        <li>
            <p>Compliance, Data Controller</p>
            <p>We appropriately handle personal data, which we collect, hold or use through our business activity, in
                compliance with applicable laws concerning protection of personal data and this Policy.</p>
            <p>Data controller of the personal data we handle in USA is as follows:</p>
            <p class="px-5">DIGI America Inc.<br>76 Veronica Ave, Somerset, NJ 08873 US</p>
            <p>Data controller of the personal data we handle worldwide is as follows:</p>
            <p class="px-5">TERAOKA SEIKO Co., Ltd.<br>13-12 Kugahara 5-chome Ohta-ku, Tokyo 146-8580 Japan</p>
        </li>
        <li>
            <p>The Purpose of the handling</p>
            <p>We handle the personal data in accordance with applicable laws for the following purposes:</p>
            <p>To achieve the administrative or accounting purposes as well as for the completion of
                transaction</p>
            <ul>
                <li>Meeting with the partner;</li>
                <li>Transactions with the partner.</li>
            </ul>
            <p>Based on your consent</p>
            <ul>
                <li>Offers of various information;</li>
                <li>Provision of information such as recruitment;</li>
                <li>Contact;</li>
                <li>Implementation of the purpose which we indicate at the time of collection.</li>
            </ul>
            <p>The personal data provision for the achievement of the administrative or accounting purposes
                as well as for the completion of transaction is necessary to achieve the statutory requirement, the
                administrative or accounting purposes as well as for the completion of the transaction. Lack of
                provision of such data may result in incompletion of necessary transaction.<br>It is your option whether
                or not you provide your personal data based on consent.</p>
        </li>
        <li>
            <p>Share</p>
            <p>We may share the personal data, such as your company name, position or contact address, with other DIGI
                Group companies or subcontractors to the extent necessary for implementing the purpose specified in the
                preceding paragraph (for example, in case it is appropriate that another DIGI Group company responds
                directly to the inquiry from the customer, or in case it is necessary to provide after-sale service of
                our products or services). In this case, we will execute an appropriate agreement with them as required
                under applicable laws and supervise appropriately them necessary for protecting personal data
                accordingly. TERAOKA SEIKO Co., Ltd. is responsible to handle the personal data shared among the DIGI
                Group.<br>
                You may find the list of DIGI Group among which your personal data will be shared at the companies which
                are in “DIGI Group” pages of this website.</p>
        </li>
        <li>
            <p>Transfer to a third country</p>
            <p>We may transfer your personal data to third countries which may not have equivalent laws concerning
                protection of personal data as your country, basically to the countries in which DIGI Group companies
                are. In this case, we take appropriate measures necessary for protecting personal data in accordance
                with applicable laws.</p>
        </li>
        <li>
            <p>Disclosure to a third party</p>
            <p>We will not disclose or provide personal data to a third party other than DIGI Group companies and
                subcontractors without your prior consent unless otherwise we are requested by law.</p>
        </li>
        <li>
            <p>Recording telephone call</p>
            <p>We may record telephone calls to accurately grasp your requests or demands, etc.</p>
        </li>
        <li>
            <p>Accuracy</p>
            <p>We make effort to keep personal data accurate and latest to the extent necessary for implementing the
                purpose specified in the Paragraph 2. When we no longer need to use the personal data, we will dispose
                of the personal data in accordance with our internal rules.</p>
        </li>
        <li>
            <p>Security control</p>
            <p>We comply with applicable laws and take appropriate measures necessary for preventing an unfair access
                and accidental disclosure of personal data. We will revise this Policy as necessary. We will retain the
                data collected for a period to achieve the purpose of retention of such data.</p>
        </li>
        <li>
            <p>Education</p>
            <p>We implement regular education to our employees for handling and protecting personal data.</p>
        </li>
        <li>
            <p>Your rights</p>
            <p>Regarding to your personal data we hold, you have the right to ask us to access, rectify, erase, or
                restrict handling of your personal data, and may object to handling your personal data or withdraw your
                consent to handling by DIGI Group, and you have the right to lodge a complaint with a supervisory
                authority. If you want to ask such, object to handling or withdraw your consent, you can send us inquiry
                with “Contact Us” in this website or to the contact detail set out in Paragraph 13 below.</p>
        </li>
        <li>
            <p>Access, rectification and erasure</p>
            <p>In case that you ask us to access, rectify, erase or restrict handling of your personal data, we respond
                such only where we can confirm that such asking is from you unless we need to handle in accordance with
                applicable laws.</p>
        </li>
        <li>
            <p>Cookies and use of this website</p>
            <p>This website uses the technology called cookie to make it more convenient for you to use this website.
                The cookies identify your computer, but never collect information which can identify yourself. You can
                refuse to be used cookies by changing your browser settings. This website may record your computer
                information as an access log to:</p>
            <ul>
                <li>Investigate the cause of problems occurring on the server and solve such;</li>
                <li>Improve this website;</li>
                <li>Use for statistical data which cannot identify individuals.</li>
            </ul>
        </li>
        <li>
            <p>Contact</p>
            <p>Questions, comments and requests regarding this Policy or handling personal data by DIGI Group are
                welcomed and should be addressed by using “Contact Us” in this website or to the below:</p>
            <p class="px-5">Marketing Department, DIGI America Inc.<br>
                76 Veronica Ave, Somerset, NJ 08873 US<br>
                (732) 828 4884 (8AM-5PM EST)<br>
                <a href="mailto:Marketing@us.digi-group.com">Marketing&#64;us.digi-group.com</a>
            </p>
        </li>
    </ol>
</section>