import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { DramsService } from '../../services/drams.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ESLController } from 'src/app/types/ESLController';
import { UtcToLocalTimePipe } from "../../pipes/utcToLocal.pipe";
import { ShelfEdgeDevice } from 'src/app/types/ShelfEdgeDevice';
import { MacAddressFormatterPipe } from "../../pipes/macaddress.pipe";
import { FormsModule } from '@angular/forms';
import { ShelfEdgeModel } from 'src/app/types/ShelfEdgeModel';
import { VideoInfo } from 'src/app/types/VideoInfo';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoadingComponent } from "../../components/loading/loading.component";

@Component({
  selector: 'app-drams-controller-details',
  standalone: true,
  imports: [CommonModule, FormsModule, UtcToLocalTimePipe, MacAddressFormatterPipe, RouterModule, FontAwesomeModule, LoadingComponent],
  templateUrl: "controller-details.component.html",
})
export class ControllerDetailsComponent implements OnInit {
  faTrash = faTrash;
  controller$!: Observable<ESLController>;

  companyId = '';
  controllerId = '';
  errorMessage = '';

  deviceData$!: Observable<{ models: ShelfEdgeModel[], videos: VideoInfo[], devices: ShelfEdgeDevice[] }>;

  private models$: Observable<ShelfEdgeModel[]>;
  private videos$: Observable<VideoInfo[]>;
  private devices$: Observable<ShelfEdgeDevice[]>;

  constructor(
    private route: ActivatedRoute,
    private dramsService: DramsService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.companyId = params.get('companyId') || '';
      this.controllerId = params.get('controllerId') || '';
      this.loadControllerDetails();
      this.loadControllerDevices();
    });
  }

  loadControllerDetails() {
    this.controller$ = this.dramsService.getControllerById(this.controllerId).pipe(
      catchError(error => {
        console.error('Error fetching controller details:', error);
        this.errorMessage = 'Failed to load controller details. Please try again later.';
        return of(null);
      })
    );
  }

  loadControllerDevices() {
    this.models$ = this.dramsService.getModels();
    this.videos$ = this.dramsService.getVideos(this.companyId);
    this.devices$ = this.dramsService.getControllerDevices(this.controllerId);

    this.deviceData$ = combineLatest([
      this.models$,
      this.videos$,
      this.devices$,
    ]).pipe(map(([models, videos, devices]) => ({ models, videos, devices })))
  }

  getModel(models: ShelfEdgeModel[], modelNumber: number) {
    return models.find(m => m.type === modelNumber);
  }

  changeDeviceVideo(device: ShelfEdgeDevice) {
    this.dramsService.updateDevice(this.controllerId, this.companyId, device).subscribe();
  }

  getVideosForModel(models: ShelfEdgeModel[], videos: VideoInfo[], modelNumber: number): VideoInfo[] {
    const model = this.getModel(models, modelNumber);
    return videos.filter(v => model.screenHeight === v.height && model.screenWidth === v.width);
  }

  deleteDevice(macAddress: string) {
    this.dramsService.deleteControllerDevice(this.controllerId, this.companyId, macAddress).subscribe(
      () => this.loadControllerDetails(),
      errorResponse => this.errorMessage = errorResponse.error
    );
  }

}