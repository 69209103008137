<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <div>
    <h1 class="mb-5">DIGI Remote Ad Management System!</h1>

    <div class="row">
      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="/documentation/getting-started">
            <fa-icon [icon]="faLink"></fa-icon> Get Started with DRAMS
          </a>
        </h6>
        <p>
          DRAMS (DIGI Remote Ad Management System) enables you to remotely manage video content on DIGI's Shelf-Edge
          Display Systems installed in stores. Learn how to set up your account and begin enhancing your in-store
          advertising.
        </p>
      </div>

      <div class="col-md"></div>

      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://www.digisystem.com/us/products/PRD00453/?business=retail">
            <fa-icon [icon]="faLink"></fa-icon> Explore the Shelf-Edge Display System
          </a>
        </h6>
        <p>
          The Shelf-Edge Display System offers high-definition video displays that attach directly to store shelves,
          capturing customer attention right at the point of purchase. Discover how this innovative solution can
          transform
          your retail space.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="/documentation/upload-content">
            <fa-icon [icon]="faLink"></fa-icon> Upload and Manage Videos
          </a>
        </h6>
        <p>
          Use DRAMS to upload new video content<!--, schedule playback times,--> and seamlessly manage your media assets
          across all shelf-edge displays.
        </p>
      </div>

      <div class="col-md"></div>

      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="/support">
            <fa-icon [icon]="faLink"></fa-icon> Contact Support
          </a>
        </h6>
        <p>
          Need assistance? Our support team is here to help with any questions or issues regarding DRAMS or the
          Shelf-Edge
          Display System.
        </p>
      </div>
    </div>
  </div>
</ng-template>