<h1>Controller Management</h1>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-container *ngIf="controller$ | async as controller; else loading">
    <div>
        <p>Name: {{ controller.name }}</p>
        <p>MAC Address: {{ controller.macAddress | macAddress}}</p>
        <p>Initalized Date: {{ controller.firstSeen | utcToLocal}}</p>
        <p>Last Update: {{ controller.lastSeen | utcToLocal}}</p>
    </div>
    <div class="row mb-3">
        <div class="col">
            <h3 class="mb-0">Displays</h3>
        </div>
        <div class="col-auto">
            <button class="btn btn-primary" [routerLink]="['device-new']">
                Add Display
            </button>
        </div>
    </div>

    <ng-container *ngIf="deviceData$ | async as deviceData">
        <div *ngIf="deviceData.devices.length === 0" class="alert alert-info">
            <h2 class="text-center">No Devices</h2>
        </div>
        <table *ngIf="deviceData.devices.length > 0" class="table table-stripe">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Mac Address</th>
                    <th>Model</th>
                    <th>Video</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr *ngFor="let device of deviceData.devices">
                    <td>{{device.name}}</td>
                    <td>{{device.macAddress | macAddress}}</td>
                    <td>{{getModel(deviceData.models, device.type).description}}</td>
                    <td>
                        <select class="form-control" [(ngModel)]="device.videoId" (change)="changeDeviceVideo(device)">
                            <option [ngValue]="undefined">No Video</option>
                            <option
                                *ngFor="let video of getVideosForModel(deviceData.models, deviceData.videos, device.type)"
                                [ngValue]="video.id">
                                {{video.name}}
                            </option>
                        </select>
                    </td>
                    <td><button class="btn btn-danger" (click)="deleteDevice(device.macAddress)"><fa-icon
                                [icon]="faTrash"></fa-icon></button></td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</ng-container>