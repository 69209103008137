import { EnvironmentConfig } from '../app/types/EnvironmentConfig';

const config: EnvironmentConfig = {
  "domain": "login-dev.digiamerica.us",
  "clientId": "tBELKBl6kkREpGFPVuTbqow8dY6VAV7Y",
  "authorizationParams": {
    "audience": "api://api-dev.digiamerica.us"
  },
  "apiUri": "https://api-dev.digiamerica.us",
  "appUri": "https://drams-dev.digiamerica.us",
  "errorPath": "/error"
};

const { domain, clientId, authorizationParams: { audience }, apiUri, errorPath } = config;

export const DRAMS_URL = 'https://api-dev.digiamerica.us/v1/drams';

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.import { EnvironmentConfig } from './EnvironmentConfig';
