import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { catchError, first, map, of } from 'rxjs';
import { faSave, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/angular-fontawesome';

export interface ConfirmationOptions {
    title?: string;
    message?: string;
    confirmText?: string;
    cancelText?: string;
    confirmButtonType?: 'primary' | 'danger' | 'warning';
    confirmIconClass?: IconDefinition;
    size?: 'sm' | 'lg' | 'xl';
}

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {
    constructor(private modalService: NgbModal) { }

    confirm(options: ConfirmationOptions = {}): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmationDialogComponent, {
            centered: true,
            size: options.size || 'lg',
            backdrop: 'static'
        });

        Object.assign(modalRef.componentInstance, options);

        return modalRef.closed.pipe(
            map(result => !!result),  // Convert result to boolean
            catchError(() => of(false)), // Handle dismissal
            first() // Complete after first value
        ).toPromise();
    }

    // Convenience methods for common confirmation types
    confirmDeletion(message = 'Are you sure you want to delete this item?') {
        return this.confirm({
            title: 'Confirm Deletion',
            message,
            confirmText: 'Delete',
            confirmButtonType: 'danger',
            confirmIconClass: faTrash
        });
    }

    confirmSave(message = 'Do you want to save your changes?') {
        return this.confirm({
            title: 'Save Changes',
            message,
            confirmText: 'Save',
            confirmButtonType: 'primary',
            confirmIconClass: faSave
        });
    }

    confirmDiscard(message = 'Are you sure you want to discard your changes?') {
        return this.confirm({
            title: 'Discard Changes',
            message,
            confirmText: 'Discard',
            confirmButtonType: 'warning',
            confirmIconClass: faTrashAlt
        });
    }
}