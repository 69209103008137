import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'utcToLocal',
    standalone: true
})
export class UtcToLocalTimePipe implements PipeTransform {
    transform(utcDateString: string, format = 'medium'): string {
        if (!utcDateString) {
            return '';
        }

        const date = new Date(utcDateString);

        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        // Convert to local time
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

        // Format options
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        };

        // Use 'medium' format by default, or custom format if provided
        if (format === 'medium') {
            return localDate.toLocaleString(undefined, options);
        } else if (format === 'short') {
            return localDate.toLocaleString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } else if (format === 'full') {
            return localDate.toLocaleString(undefined, {
                ...options,
                weekday: 'long',
                month: 'long'
            });
        }

        // If custom format is not recognized, return the default 'medium' format
        return localDate.toLocaleString(undefined, options);
    }
}