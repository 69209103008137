import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'macAddress',
    standalone: true
})
export class MacAddressFormatterPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }

        // Remove any non-hexadecimal characters and convert to uppercase
        const cleanedValue = value.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();

        // Check if the cleaned value is exactly 12 characters long
        if (cleanedValue.length !== 12) {
            return 'Invalid MAC Address';
        }

        // Insert colons after every two characters
        return cleanedValue.match(/.{1,2}/g)?.join(':') || 'Invalid MAC Address';
    }
}